import { Component } from '@angular/core';

@Component({
  selector: 'app-page-error-notfound',
  templateUrl: './page-error-notfound.component.html',
  styleUrls: ['./page-error-notfound.component.scss']
})
export class PageErrorNotfoundComponent {

}
